<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.Course_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.sidebar {
  width: 35%;
  height: 100%;
  background-color: #343a40;
  color: white;
}
.sidebar a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}
.sidebar a:hover {
  background-color: #FAF9F6;
  color: black;
  cursor: pointer;
}
.sidebar a i {
  margin-right: 10px;
}
.sidebar a .fas.fa-chevron-right {
  margin-left: auto;
}
.content {
  flex-grow: 1;
  display: flex;
  width: 100%;
  justify-content: top;
  align-items: center;
  /* background-color: white; */
}
.content img {
  max-width: 100%;
  height: auto;
}
.scrolling-container {
  padding-top: 0;
  height: 80%; /* Set the desired maximum height */
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
}
@media (max-width: 600px) {
  .Course_container .sidebar{
    width: 37%;
  }
  .Course_container .scrolling-container{
    width: 73%;
  }
  .Course_container .sidebar h1{
    font-size: 20px;
  }  
  .Course_container .sidebar a{
    font-size: 10px;
    padding: 0px 0px;
  }
}
</style>

<template>
  <Header />
  <div class="Course_container">
    <div class="sidebar">
      <div>
        <h1>Courses</h1>
        <ul>
          <li>
            <a @click="showContent('Developmental Gymnastics')"
              >Developmental Gymnastics</a
            >
          </li>
          <li>
            <a @click="showContent('Artistic gymnastics')"
              >Artistic gymnastics</a
            >
          </li>
          <li>
            <a @click="showContent('Adult gymnastics')"
              >Adult gymnastics</a
            >
          </li>
          <li>
            <a @click="showContent('Calisthenics')"
              >Calisthenics</a
            >
          </li>
          <li>
            <a @click="showContent('Healthy Weight Session')"
              >Healthy Weight Session</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="scrolling-container">
      <p v-if="contentTitle === 'Developmental Gymnastics' "> <DevelopmentalGym /> </p>
      <p v-else-if="contentTitle === 'Artistic gymnastics' "> <ArtisticGym /> </p>
      <p v-else-if="contentTitle === 'Adult gymnastics' "> <AdultGym /> </p>
      <p v-else-if="contentTitle === 'Calisthenics' "> <Calisthenics /> </p>
      <p v-else-if="contentTitle === 'Healthy Weight Session' "> <HealthyWeight /> </p>
      <p v-else> <DevelopmentalGym /> </p>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import DevelopmentalGym from "./coursesPages/DevelopmentalGym.vue";
import Calisthenics from "./coursesPages/Calisthenics.vue";
import HealthyWeight from "./coursesPages/HealthyWeight.vue";
import AdultGym from "./coursesPages/AdultGym.vue";
import ArtisticGym from "./coursesPages/ArtisticGym.vue";
export default {
   data() {
                return {
                    contentTitle: 'Developmental Gymnastics ',
                    contentText: "DevelopmentalGym" ,
                }
            },
  name: "OurCourses",
  
  methods: {
                showContent(section) {
                    switch(section) {
                        case 'Developmental Gymnastics':
                            this.contentTitle = 'Developmental Gymnastics';
                            break;
                        case 'Artistic gymnastics':
                            this.contentTitle = 'Artistic gymnastics';
                            break;
                        case 'Adult gymnastics':
                            this.contentTitle = 'Adult gymnastics';
                            break;
                        case 'Calisthenics':
                            this.contentTitle = 'Calisthenics';
                            break;
                        case 'Healthy Weight Session':
                            this.contentTitle = 'Healthy Weight Session';
                            break;
                        default:
                            this.contentTitle = 'Developmental Gymnastics';
                    }
                }
            },
 components: {
    Header,
    Footer,
    DevelopmentalGym,
    AdultGym,
    Calisthenics,
    HealthyWeight,
    ArtisticGym
  },
};
</script>
