<template>
  <div class="developmental-gymnastics">
    <h1>Developmental Gymnastics</h1>
    <p>
      Developmental gymnastics refers to a type of gymnastics program designed
      specifically for young children, typically from toddlers to pre-teens, to
      help them develop foundational motor skills, physical fitness, and body
      awareness.
    </p>

    <section id="key-aspects">
      <h2>Key Aspects of Developmental Gymnastics</h2>
      <ul>
        <li>
          <h3>Physical Development</h3>
          <ul>
            <li>
              Emphasizes strength, flexibility, balance, and coordination.
            </li>
            <li>
              Exercises focus on core strength, spatial awareness, and gross
              motor development.
            </li>
          </ul>
        </li>
        <li>
          <h3>Basic Movement Skills</h3>
          <ul>
            <li>
              Children learn basic motor skills such as running, jumping,
              rolling, balancing, and coordination.
            </li>
            <li>
              Activities are designed to improve body control, agility, and
              flexibility.
            </li>
          </ul>
        </li>
        <li>
          <h3>Pre-Gymnastics Skills</h3>
          <ul>
            <li>
              Introduces fundamental gymnastics skills like cartwheels, forward
              rolls, handstands, and basic tumbling.
            </li>
          </ul>
        </li>
        <li>
          <h3>Social and Emotional Growth</h3>
          <ul>
            <li>
              Encourages confidence-building, social interaction, discipline,
              and perseverance.
            </li>
            <li>
              Children learn to follow instructions, work in teams, and deal
              with challenges in a supportive environment.
            </li>
          </ul>
        </li>
        <li>
          <h3>Cognitive Development</h3>
          <ul>
            <li>
              Encourages problem-solving and focus, as children must plan and
              execute movements.
            </li>
            <li>
              Some programs integrate educational themes to enhance learning
              (e.g., using colors, shapes, or numbers in exercises).
            </li>
          </ul>
        </li>
        <li>
          <h3>Motor Skills Development</h3>
          <ul>
            <li>
              Emphasis on basic movements like running, jumping, rolling,
              balancing, and swinging.
            </li>
            <li>
              Helps improve gross motor skills (large movements involving the
              arms, legs, and entire body).
            </li>
          </ul>
        </li>
        <li>
          <h3>Safe and Fun Environment</h3>
          <ul>
            <li>
              Classes are often non-competitive and designed to be enjoyable,
              using obstacle courses, soft equipment, and creative games.
            </li>
            <li>
              Focuses on developing a love for physical activity in a safe,
              positive atmosphere.
            </li>
          </ul>
        </li>
      </ul>
    </section>

    <section id="methods-used">
      <h2>Methods Used in Developmental Gymnastics</h2>
      <ul>
        <li>
          <h3>Fun, Structured Play</h3>
          <p>
            Engages children with fun, interactive activities to keep them
            motivated.
          </p>
        </li>
        <li>
          <h3>Small Equipment</h3>
          <p>
            Soft mats, balance beams, and low bars suited for younger children
            to safely explore movements.
          </p>
        </li>
        <li>
          <h3>Progressive Learning</h3>
          <p>
            Gradually teaches new skills as children grow and develop, ensuring
            they build strength and coordination at their own pace.
          </p>
        </li>
      </ul>
    </section>

    <p>
      Developmental gymnastics programs provide a strong foundation for children
      who may want to progress to competitive gymnastics or other sports in the
      future, while also promoting general physical health and development.
    </p>
  </div>
</template>

<script>
export default {
  name: "DevelopmentalGymnastics",
};
</script>

<style>
.developmental-gymnastics {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  /* color: white; */
  color: black;
  align-items: left;
  text-align: left;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

h1,
h2,
h3 {
  color: black;
  /* align-content: left; */
}
</style>
