<template>
  <div class="artistic-gymnastics">
    <h1>Artistic Gymnastics</h1>
    <p>
      Artistic gymnastics is a highly demanding sport that combines strength, flexibility, balance, coordination, and grace. It involves performing a variety of routines on different apparatuses, each designed to showcase a gymnast’s skills in acrobatics, strength, and aesthetics.
    </p>

    <section id="why-choose-artistic-gymnastics">
      <h2>Why Choose Artistic Gymnastics?</h2>
      <ul>
        <li>
          <p>Artistic gymnastics is a thrilling sport that challenges the body and mind, blending athleticism with creativity. It builds strength, flexibility, coordination, and discipline, and is admired for its beauty, precision, and dynamic energy.</p>
        </li>
        <li>
          <p>Artistic gymnastics is a demanding yet beautiful sport that showcases the perfect blend of physical prowess and artistic expression. It requires years of dedication, precision, and creativity, making it one of the most captivating events in the world of sports.</p>
        </li>
      </ul>
    </section>

    <section id="men-artistic-gymnastics">
      <h2>Men Artistic Gymnastics</h2>
      <p>
        The Gymnastics School Men Artistic Gymnastics (MAG) competitive programme is structured to prepare gymnasts for internal and external high-level competitions under the Gymnastics Federation of India (GFI) and Fédération Internationale de Gymnastique (FIG) Levels standards.
      </p>
      <ul>
        <li>
          <p>The programme focuses on developing and perfecting skill sets on the six Olympic apparatus (Floor, Pommel, Rings, Vault, Parallel Bars and High Bar).</p>
        </li>
        <li>
          <p>Our gymnasts have successfully represented the club and country at local club, national and international events.</p>
        </li>
      </ul>
    </section>

    <section id="women-artistic-gymnastics">
      <h2>Women Artistic Gymnastics</h2>
      <p>
        The Gymnastics School women Artistic Gymnastics (WAG) competitive programme is structured to prepare gymnasts for internal and external high-level competitions under the Gymnastics Federation of India (GFI) and Fédération Internationale de Gymnastique (FIG) Levels standards.
      </p>
      <ul>
        <li>
          <p>The programme focuses on developing and perfecting skill sets on the six Olympic apparatus (Vault, Bars, Beam and Floor).</p>
        </li>
        <li>
          <p>Our gymnasts have successfully represented the club and country at local club, national and international events.</p>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ArtisticGymnastics',
};
</script>

<style scoped>
.artistic-gymnastics {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  color: white;
  color: black;
  align-items: left;
  text-align: left;
}

h1, h2 {
  color: black;
}
</style>