<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin: 0;
  padding-top: 0%;
  background-color: rgb(245, 85, 85);
  background-image: url(./assets/images1.jpeg);
  background-repeat:no-repeat;
  background-size:100% 100%;
  /* rgb(250, 215, 215); */
}
body{
  padding: 0;
  margin: 0;
}
</style>
