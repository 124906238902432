<template>
  <Header />
  <div class="Team_container">
    <div class="header">
      <h2>OUR TEAM</h2>
      <h1>Remarkable team at our Gymnastics Training Academy</h1>
      <p>
        Our coaching staff is composed of seasoned professionals with extensive
        experience at both competitive and recreational levels.
      </p>
    </div>
    <div class="team">
      <div v-for="member in teamMembers" :key="member.id" class="team-member">
        <img :src="member.image" :alt="'Portrait of ' + member.name" />
        <h3>{{ member.name }}</h3>
        <p :class="member.roleClass">{{ member.role }}</p>
        <p>{{ member.description }}</p>
      </div>
    </div>
    <div class="navigation">
      <button @click="prevPage"><i class="fas fa-chevron-left"></i></button>
      <button @click="nextPage"><i class="fas fa-chevron-right"></i></button>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "OurTeam",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      teamMembers: [
        {
          id: 1,
          name: "Vishal Jain",
          role: "Managing Director",
          roleClass: "Operations",
          image: require("../assets/vishal.jpg"),
          description:
            "Passionate for changing life and dedicated to promoting health, fitness, and the joy of movement in every human life!",
        },
        {
          id: 2,
          name: "Anita Prajapati",
          role: "Head Women Coach",
          roleClass: "Coach",
          image: require("../assets/anita.jpg"),
          description:
            "As head women gymnastics coach in the gymnastics school, my mission is to help you reaching for new heights, my focus is on building not only skill, but confidence, discipline, and passion.",
        },
        {
          id: 3,
          name: "Karan Arela",
          role: "Head Men Coach",
          roleClass: "Coach",
          image: require("../assets/karan.jpg"),
          description:
            "As a dedicated head men gymnastics coach in the gymnastics school, my mission is to inspire athletes to push their limits, build confidence, and achieve their full potential. Together, we’ll transform hard work into success—one skill, one routine, one leap at a time. Let’s elevate your gymnastics journey!",
        },
        {
          id: 4,
          name: "Gourav Singh Rathor",
          role: "Coach",
          roleClass: "Coach",
          image: require("../assets/Gourav.jpg"),
          description:
            "As your coach in the gymnastics school, my mission is to help you unlock your potential through gymnastics. Whether you're here to build skills, boost fitness, or challenge yourself, together we'll achieve your goals with dedication, discipline, and passion. Let's soar to new heights!",
        },        
      ],
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  computed: {
    paginatedTeamMembers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.teamMembers.slice(start, end);
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.teamMembers.length) {
        this.currentPage++;
      }
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
  text-align: center;
}
.Team_container {
  /* max-width: 1200px; */
  padding: 20px;
  margin: 0 auto;
}
.header {
  margin-bottom: 40px;
  margin-top: 40px;
}
.header h2 {
  /* color: #e6e9ec; */
  color: #000;
  font-size: 34px;
  margin: 0;
}
.header h1 {
  color: #343a40;
  font-size: 40px;
  margin: 10px 0;
}
.header p {
  /* color: #e6e9ec; */
  color: #000;
  font-size: 16px;
  margin: 0;
}
.team {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.team-member {
  background-color: #fff;
  border-radius: 10px;
  border-color: blue;
  border-style: solid;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  max-width: 250px;
  flex: 1 1 calc(100% - 40px);
  width: calc(20% - 20px);
  box-sizing: border-box;
}
.team-member img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}
.team-member h3 {
  color: #343a40;
  font-size: 18px;
  margin: 10px 0 5px;
}
.team-member p.Coach {
  color: #fd7e14;
  font-size: 14px;
  margin: 0;
}
.team-member p.dev {
  color: #fd7e14;
}
.team-member p.Operations {
  color: #fd7e14;
  font-size: 14px;
  margin: 0;
}
.navigation {
  margin-top: 20px;
}
.navigation button {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  color: #6c757d;
  cursor: pointer;
  font-size: 18px;
  margin: 0 5px;
  padding: 10px;
}
@media (min-width: 600px) {
  .team-member {
    flex: 1 1 calc(50% - 40px);
  }
}
@media (min-width: 900px) {
  .team-member {
    flex: 1 1 calc(25% - 40px);
  }
}
</style>
