<template>
  <div class="calisthenics">
    <h1>Calisthenics</h1>
    <p>
      Calisthenics is a form of exercise that involves using your own body weight to build strength, improve flexibility, and increase endurance.
    </p>

    <section id="benefits-of-calisthenics">
      <h2>Benefits of Calisthenics</h2>
      <ul>
        <li>
          <h3>Improves Strength and Muscle Tone</h3>
          <p>Uses body weight to target multiple muscle groups.</p>
        </li>
        <li>
          <h3>Increases Flexibility and Mobility</h3>
          <p>Many exercises promote a full range of motion, improving flexibility.</p>
        </li>
        <li>
          <h3>Boosts Cardiovascular Fitness</h3>
          <p>Exercises can be performed in circuits to elevate heart rate and improve endurance.</p>
        </li>
        <li>
          <h3>No Equipment Needed</h3>
          <p>Most calisthenics exercises rely solely on body weight, making it accessible to everyone.</p>
        </li>
        <li>
          <h3>Enhances Balance and Coordination</h3>
          <p>Movements require coordination and body control, improving balance.</p>
        </li>
        <li>
          <h3>Functional Strength</h3>
          <p>Strength gained from calisthenics is practical for real-life movements and activities.</p>
        </li>
      </ul>
    </section>

    <section id="why-choose-calisthenics">
      <h2>Why Choose Calisthenics?</h2>
      <ul>
        <li>
          <p>Body Control: It teaches you how to move and control your body efficiently.</p>
        </li>
        <li>
          <p>Versatility: Exercises can be done anywhere and can be adapted for all fitness levels, from beginners to advanced athletes.</p>
        </li>
        <li>
          <p>Minimal Equipment: Some exercises (like pull-ups) may require a bar, but most exercises can be performed without any equipment.</p>
        </li>
      </ul>
    </section>

    <p>
      Calisthenics is perfect for anyone looking to build functional strength, improve fitness, and develop a toned physique without the need for weights or machines.
    </p>
  </div>
</template>

<script>
export default {
  name: 'CalisthenicS',
};
</script>

<style scoped>
.calisthenics {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  /* color: white; */
  color: black;
  align-items: left;
  text-align: left;
}

h1, h2 {
  color: black;
  align-items: left;
  text-align: left;
}
</style>