<template>
  <Header />
  <div>
    <div class="hero">
      <div class="hero-text">
        <h1>ELEVATE YOUR SKILLS WITH EXPERT COACHING</h1>
        <p>Experience the thrill of the game with The Gymnastic School.</p>
      </div>
      <div class="hero-image">
        <img
          src="https://storage.googleapis.com/a1aa/image/1qnRxF0UoJaeV6pqrdmtTiaCFZIZ2BCBov7PfYYfMEvUGILnA.jpg"
          alt="Gymnastic training area with equipment"
        />
      </div>
    </div>
    <div class="about">
      <img
        src="https://storage.googleapis.com/a1aa/image/yg2kBT9IeVy9eEOlrULYiVy8afD8W4apzzNLPgP1OOXNGILnA.jpg"
        alt="Ballet dancers in training"
      />
      <div class="about-text">
        <h2>Our Training Philosophy</h2>
        <p>
          At The Gymnastics School, we believe in a holistic approach to sports training
          that focuses on both physical and mental development. Our training
          programs are designed to help athletes build strength, endurance, and
          agility, as well as to develop the mental toughness they need to
          succeed on and off the field.
        </p>
      </div>
    </div>
    <div class="about">
      
      <div class="about-text">
        <h2>Our Training Philosophy</h2>
        <p>
          We focus on building strong foundations in technique, flexibility, and strength while fostering a love for movement and teamwork. Join us as we inspire the next generation to reach their full potential, cultivate confidence, and promote lifelong health and wellness through the exciting world of gymnastics!
        </p>
      </div>
      <img
        src="https://storage.googleapis.com/a1aa/image/i0EoLoqSy6ZZI1l0u4lvw8veaiaVNeeWomokahwApkhPGILnA.jpg"
        alt="Ballet dancers in training"
      />
    </div>
    <div class="gallery">
      <h2>
        GLIMPSES OF THE GYMNASTIC SCHOOL: CAPTURING THE ESSENCE OF THE CLUB
      </h2>
      <div class="gallery-images">
        <!-- <img
          src="https://storage.googleapis.com/a1aa/image/7sqnmze9SExkN6fW76CfZb3fuXCTM6RNApINMDXeSnnvZgscC.jpg"
          alt="Gymnastic training session"
        /> -->
        <img
          src="https://storage.googleapis.com/a1aa/image/yg2kBT9IeVy9eEOlrULYiVy8afD8W4apzzNLPgP1OOXNGILnA.jpg"
          alt="Gymnast performing on the floor"
        />
        <!-- <img
          src="https://storage.googleapis.com/a1aa/image/Sc9Sl9Mca5LyOBfCO89uZghJaw3FhiZLV197lwVnfNGDDklTA.jpg"
          alt="Two gymnasts wrestling"
        /> -->
        <img
          src="https://storage.googleapis.com/a1aa/image/hCoOYkujIJIcKZuBu3D7KUlVgoHHpP4jYgZNyl13WXTyAZ5E.jpg"
          alt="Gymnast practicing on the balance beam"
        />
        <img
          src="https://storage.googleapis.com/a1aa/image/i0EoLoqSy6ZZI1l0u4lvw8veaiaVNeeWomokahwApkhPGILnA.jpg"
          alt="Gymnast stretching with a ball"
        />
        <!-- <img
          src="https://storage.googleapis.com/a1aa/image/PYAagXSBepxHFqpbD5j3qngV188mgvum2Dxx6IejHoPMDklTA.jpg"
          alt="Gymnast performing a split"
        /> -->
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: #fff; */
  background-color: #000;
  color: #000;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.hero-text {
  text-align: center;
  margin-bottom: 20px;
}
.hero-text h1 {
  font-family: "Playfair Display", serif;
  font-size: 36px;
  color: #000;
  margin: 0;
}
.hero-text p {
  font-size: 16px;
  color: #000;
  margin: 20px 0;
}
.hero-text button {
  background-color: #000;
  color: #000;
  /* color: #fff; */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
.hero-image img {
  max-width: 100%;
  height: auto;
}
.about {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.about-text {
  text-align: center;
}
.about-text h2 {
  font-family: "Playfair Display", serif;
  color: #000;
  font-size: 24px;
}
.about-text p {
  font-size: 16px;
}
.gallery {
  padding: 20px;
}
.gallery h2 {
  font-family: "Playfair Display", serif;
  font-size: 24px;
  text-align: center;
}
.gallery-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.gallery-images img {
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .hero {
    flex-direction: row;
    justify-content: space-between;
    padding: 50px;
  }
  .hero-text {
    max-width: 50%;
    text-align: left;
  }
  .hero-text h1 {
    font-size: 48px;
  }
  .hero-text p {
    font-size: 18px;
  }
  .about {
    flex-direction: row;
    align-items: center;
    padding: 50px;
  }
  .about img {
    max-width: 50%;
    margin-bottom: 0;
  }
  .about-text {
    max-width: 50%;
    padding-left: 20px;
    text-align: left;
  }
  .gallery-images img {
    width: 32%;
  }
}
</style>
