<template>
  <div class="adult-gymnastics">
    <h1>Adult Gymnastics</h1>
    <p>
      Adult gymnastics refers to gymnastics’ programs designed for individuals who begin or continue the sport later in life, typically beyond the traditional childhood and teen years.
    </p>

    <section id="benefits-of-adult-gymnastics">
      <h2>Benefits of Adult Gymnastics</h2>
      <ul>
        <li>
          <h3>Strength and Flexibility</h3>
          <ul>
            <li>Gymnastics involves bodyweight exercises that build overall strength, especially in the core, upper body, and legs.</li>
            <li>Flexibility is developed through stretching and movements such as splits, backbends, and extensions.</li>
          </ul>
        </li>
        <li>
          <h3>Balance and Coordination</h3>
          <ul>
            <li>Many gymnastics exercises require precise movements and control, which improves balance and overall coordination.</li>
            <li>Balance skills can also help prevent injury and enhance performance in other physical activities.</li>
          </ul>
        </li>
        <li>
          <h3>Body Awareness and Control</h3>
          <ul>
            <li>Gymnastics promotes proprioception, which is the body’s ability to sense its position in space.</li>
            <li>This helps in maintaining better posture, coordination, and control over movements.</li>
          </ul>
        </li>
        <li>
          <h3>Cardiovascular Fitness</h3>
          <ul>
            <li>Certain routines, especially tumbling or high-energy movements, can provide a good cardiovascular workout, improving heart health and stamina.</li>
          </ul>
        </li>
        <li>
          <h3>Mental Focus and Confidence</h3>
          <ul>
            <li>Learning new skills and mastering techniques requires concentration and patience.</li>
            <li>Over time, adult gymnasts build mental resilience, focus, and confidence.</li>
          </ul>
        </li>
        <li>
          <h3>Stress Relief and Enjoyment</h3>
          <ul>
            <li>Engaging in gymnastics is a fun and dynamic way to relieve stress.</li>
            <li>It’s also a social activity, as many adults enjoy the community aspect of adult gymnastics classes or clubs.</li>
          </ul>
        </li>
      </ul>
    </section>

    <section id="common-skills-and-movements">
      <h2>Common Skills and Movements for Adult Gymnasts</h2>
      <ul>
        <li>
          <p>Basic Tumbling: Forward rolls, cartwheels, handstands, and round-offs.</p>
        </li>
        <li>
          <p>Strength and Conditioning: Core exercises, pull-ups, dips, and strength holds.</p>
        </li>
        <li>
          <p>Flexibility Work: Splits, bridges, backbends, and stretching exercises.</p>
        </li>
        <li>
          <p>Apparatus Use: Depending on the facility, adults may work on skills using the vault, balance beam, parallel bars, rings, or trampolines.</p>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AdultGymnastics',
};
</script>

<style scoped>
.adult-gymnastics {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  /* color: white; */
  color: black;
  align-items: left;
  text-align: left;
}

h1, h2 {
  color: black;
}
</style>