<template>
  <div class="healthy-weight-session">
    <h1>Healthy Weight Session</h1>
    <p>
      A healthy weight session focuses on exercises and routines that help maintain or achieve a healthy body weight by promoting fat loss, building muscle, and improving overall fitness.
    </p>

    <section id="components-of-a-healthy-weight-session">
      <h2>Components of a Healthy Weight Session</h2>
      <ul>
        <li>
          <h3>Warm-up (5-10 minutes)</h3>
          <ul>
            <li>Start with light aerobic exercises to increase heart rate and blood flow to the muscles.</li>
            <li>Activities: Brisk walking, jogging, cycling, or dynamic stretches (arm swings, leg kicks).</li>
          </ul>
        </li>
        <li>
          <h3>Main Workout (20-40 minutes)</h3>
          <p>This part of the session focuses on a combination of:</p>
          <ul>
            <li>
              <h4>High-Intensity Interval Training (HIIT) for fat loss:</h4>
              <p>HIIT alternates between short bursts of intense exercise and periods of rest or low-intensity activity.</p>
              <p>Example Circuit (20 minutes):</p>
              <ul>
                <li>Jump Squats: 30 seconds on, 30 seconds rest</li>
                <li>Mountain Climbers: 30 seconds on, 30 seconds rest</li>
                <li>Burpees: 30 seconds on, 30 seconds rest</li>
                <li>Plank to Push-Up: 30 seconds on, 30 seconds rest</li>
                <li>Repeat this circuit 3-4 times for a high-calorie burn.</li>
              </ul>
            </li>
            <li>
              <h4>Strength Training for muscle toning:</h4>
              <p>Strength training helps build lean muscle mass, which increases resting metabolism (burning more calories even at rest).</p>
              <p>Example Strength Routine (15-20 minutes):</p>
              <ul>
                <li>Pull ups: 3 sets of 10 reps</li>
                <li>Push-ups: 3 sets of 10-12 reps</li>
                <li>Wall-bar Tuck raise: 3 sets of 12 reps per</li>
                <li>Walking Lunges: 3 sets of 10 reps per leg</li>
                <li>Pike Sits (for core): 3 sets of 20 reps</li>
              </ul>
            </li>
            <li>
              <h4>Cardiovascular Exercise:</h4>
              <p>A steady-state cardio workout can complement strength training.</p>
              <p>This helps with long-term fat loss and heart health.</p>
            </li>
          </ul>
        </li>
        <li>
          <h3>Cool-down (5-10 minutes)</h3>
          <ul>
            <li>Light Stretching: Stretch the major muscle groups used during the workout.</li>
            <li>Deep Breathing: Engage in controlled breathing to bring your heart rate back to normal.</li>
            <li>Walking: Slow, steady walking to let your body relax.</li>
          </ul>
        </li>
      </ul>
    </section>

    <section id="post-workout-stretching">
      <h2>Post-Workout Stretching (5 minutes)</h2>
      <p>Focus on improving flexibility and preventing injury:</p>
      <ul>
        <li>Hamstring stretches</li>
        <li>Quadriceps stretches</li>
        <li>Shoulder stretches</li>
        <li>Hip flexor stretches</li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HealthyWeightSession',
};
</script>

<style scoped>
.healthy-weight-session {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  /* color: white; */
  color: black;
  align-items: left;
  text-align: left;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

h1,
h2 {
  color: black;
  align-content: left;
}
</style>